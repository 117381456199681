import React, {Fragment} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';

const ButtonFloating = ( {link} ) => {
    return (
        <Fragment>
           <CopyToClipboard
                text={link}
            >
                <button className="icon-box"
                    data-effect={"solid"} data-place={"bottom"} 
                    data-multiline={true} data-tip={ "Link Copiado!" } data-event='focus'
                >
                    <i className="fas fa-link icon-share"></i>
                </button>
            </CopyToClipboard>
            <div className="r-tooltip r-tooltip-alt">
                <ReactTooltip afterShow={() => {setTimeout(() => ReactTooltip.hide(), 1000); }}/>
            </div> 
        </Fragment>
    )
}
  
export default ButtonFloating;