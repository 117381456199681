import React, { Fragment } from 'react';
import Badge from '../../components/badges/Badge';
import Card from '../../components/cards/Card';

const Overview = ({ project, id, classes }) => {
    const renderOverview = (project, id) => {
        return (
            <Fragment>
                <div className="d-block d-sm-flex text-center text-sm-start mt-2 mt-sm-0">
                    <div className={"flex-shrink-0 mx-auto me-sm-3 icon-letter icon-letter-lg " + classes.bgColor}>
                        {project.projectName.charAt(0)}
                    </div>
                    <div className="mt-3 mt-sm-auto">
                        <div>{project.objective}</div>
                        <div className="mt-3">
                            <span className="fw-bolder-light">Status do projeto: </span>
                            <span className={project.status === "Sustentação" ? "text-success" : "text-base"}>{project.status}</span>
                        </div>
                        <div className="mt-3">
                            <div className="me-2 mb-2 fw-bolder-light">
                                {project.status === "Não Iniciado" ? "Tecnologias que serão aplicadas no projeto:" : "Tecnologias aplicadas no projeto:"}
                            </div>
                            {
                                project.technologies.map((technology, id) => (
                                    <Fragment key={id}>
                                        <Badge content={technology} classes={classes.badgeColor}/> 
                                    </Fragment>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="mt-3 text-center text-sm-end">
                    {
                        project.url ? (
                            <a className={"btn btn-primary btn-lg btn-double d-flex d-sm-inline-block align-items-center justify-content-center " + classes.btnColor} href={project.url} target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-desktop icon-desktop pt-2 pt-sm-0"></i>
                                <div className="text-center d-inline-block ps-3 ps-sm-0">
                                    {
                                        project.demo === true ? "Demonstração do " + project.projectName.replaceAll(' - wCastro', '')
                                        : "Acessar o " + project.projectName.replaceAll(' - wCastro', '')
                                    }
                                    <br />
                                    {
                                        project.env === 'test' ? <small>(Ambiente de Teste)</small> 
                                        : <small>(Ambiente de Produção)</small>
                                    }
                                </div>
                            </a>
                        ) : null
                    }
                    {
                        project.url2 ? (
                            <a className={"btn btn-primary btn-lg btn-double ms-md-3 mt-3 mt-md-0"} href={project.url2} target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-desktop icon-desktop"></i>
                                <div className="text-center d-inline-block">
                                    Acessar o Painel do {project.projectName.replaceAll(' - wCastro', '')}
                                    <br />
                                    {
                                        project.env === 'test' ? <small>(Ambiente de Produção)</small> 
                                        : <small>(Ambiente de Produção)</small>
                                    }
                                </div>
                            </a>
                        ) : null
                    }
                    {
                        project.github ? (
                            <a className={"btn btn-dark btn-lg btn-double d-flex d-sm-inline-block align-items-center justify-content-center ms-md-3 mt-3 mt-md-0"} href={project.github} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-github icon-github"></i>
                                <div className="text-center d-inline-block ps-3 ps-sm-0">
                                    Acessar o Repositório no Github
                                    <br />
                                    <small>(Github)</small>
                                </div>
                            </a>
                        ) : null
                    }
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
           <Card content={ renderOverview(project, id) } />
        </Fragment>
    )
}
  
export default Overview;