import React from 'react';
import Card from '../cards/Card';

const HeadPage = ({ icon, title, classes }) => {
    const renderHeadPage = () => {
        return (
            <div className={classes}>
                {icon}
                <p className="mb-0 ms-3 d-inline-block text-green-400 fs-md fw-bolder-light">{ title }</p>
            </div>
        )
    }

    return (
        <section className="head-page">
            <Card id={'head-page'} content={renderHeadPage()} classes={'card-head'}/>
        </section>
    )
}
  
export default HeadPage;