import './assets/styles/main.scss';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/headers/Header';
import Footer from './components/footers/Footer';
import Routes from './routes/Routes';
import ButtonFloating from './components/buttons/ButtonFloating';
import ButtonFloatingToggle from './components/buttons/ButtonFloatingToggle';
import ContextWrapper from './contexts/ContextWrapper';

function App() {
  return (
    <ContextWrapper>
      <BrowserRouter>
        <div className="App">
          <Header />
          <main className="pb-3">
            <div className="container mt-4 px-2 px-sm-auto">
              <Routes />
            </div>
          </main>
          <Footer />
          <ButtonFloating
            link="https://api.whatsapp.com/send?phone=5511914640755"
            icon={<i className='fab fa-whatsapp'></i>}
            classes="btn-floating btn-floating-whatsapp pulse"
          />
          {
            !window.matchMedia('(display-mode: standalone)').matches ? (
              <ButtonFloatingToggle
                icon={<i className="fas fa-expand"></i>}
                classes="btn-floating btn-floating-fullscreen"
              />
              ) : null
          }
        </div>
      </BrowserRouter>
    </ContextWrapper>
  );
}

export default App;