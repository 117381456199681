import React, { Fragment, useState } from 'react';
import Github from '../../../assets/images/logos-svgs/github-logo.svg';
import Google from '../../../assets/images/logos-svgs/google-logo.svg';
/* import Packt from '../../../assets/images/logos-svgs/packt-logo.svg'; */
import StackOverflow from '../../../assets/images/logos-svgs/stackoverflow-logo.svg';
import Udemy from '../../../assets/images/logos-svgs/udemy-logo.svg';
import Medium from '../../../assets/images/logos-svgs/medium-logo.svg';
import W3Schools from '../../../assets/images/logos-svgs/w3schools-logo.svg';
import Card from '../../../components/cards/Card';
import Head from '../../../components/heads/Head';

const SourcesKnowledge = () => {
    const initialState = [
        {
            title: "Google",
            content: "Mecanismo de pesquisa onde é possível localizar facilmente qualquer tipo de informação desejada.",
            image: Google,
            link: "https://www.google.com.br/" 
        },
        {
            title: "Udemy",
            content: "Plataforma de cursos online sobre programação, desenvolvimento, PHP, Javascript, Laravel, APIs e muito mais.",
            image: Udemy,
            link: "https://www.udemy.com/pt/" 
        },
        {
            title: "w3schools",
            content: "Site educacional que contém uma variedade de tutoriais e referências relacionadas às linguagens de desenvolvimento web.",
            image: W3Schools,
            link: "https://www.w3schools.com/" 
        },
        /* {
            title: "Packt",
            content: "Plataforma de aprendizagem online onde é possível aprender PHP, Javascript, Laravel e muito mais.",
            image: Packt,
            link: "https://www.packtpub.com/" 
        }, */
        {
            title: "Stack Overflow",
            content: "Plataforma de perguntas e respostas para profissionais e interessados em tecnologia e programação.",
            image: StackOverflow,
            link: "https://pt.stackoverflow.com/" 
        },
        {
            title: "GitHub",
            content: "Plataforma de hospedagem de código-fonte que permite os desenvolvedores compartilharem os seus projetos.",
            image: Github,
            link: "https://github.com/" 
        },
        {
            title: "Medium",
            content: "Plataforma de publicação de conteúdo que contém diversos assuntos relacionados à tecnologias.",
            image: Medium,
            link: "https://medium.com/" 
        },
    ];

    const [sources] = useState(initialState);

    const renderSources = (source) => {
        return (
            <Fragment>
                <div className="card-logo">
                    <img src={source.image} alt={source.title} />
                </div>
                <div className="card-link text-center">
                    <strong>{source.title}</strong>
                    <p className="mt-1 mb-1">{source.content}</p>
                </div>
            </Fragment>
        )
    }

    return (
        <section className="sources-knowledge">
            <div className="row">
                <Head content={'Fontes de Conhecimento'} classes={'mt-2'} />
                {
                    sources.map((value, key) => (
                        <div key={key} className="col-12 col-sm-6 col-lg-3 col-xxl-2 mb-4">
                            <a href={value.link} className="link-hover" target="_blank" rel="noopener noreferrer">
                                <Card content={renderSources(value)} classes={'h-100'}/>
                            </a>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}
  
export default SourcesKnowledge;