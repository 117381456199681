import React, { Fragment, useState } from 'react';
import Card from '../../../components/cards/Card';
import Head from '../../../components/heads/Head';
import ProgressBar from '../../../components/progress/ProgressBar';

const Skills = () => {
    const initialState = {
        backEnd: [
            {
                name: "PHP",
                percentLevel: 70,
                type: "Back-End"
            },
            {
                name: "Laravel",
                percentLevel: 73,
                type: "Back-End"
            },
            {
                name: "Javascript",
                percentLevel: 45,
                type: "Back-End"
            },
            {
                name: "Node.js",
                percentLevel: 40,
                type: "Back-End"
            },
            {
                name: "Express",
                percentLevel: 45,
                type: "Back-End"
            },
            {
                name: "MySQL",
                percentLevel: 65,
                type: "Back-End"
            },
            {
                name: "PostgreSQL",
                percentLevel: 40,
                type: "Back-End"
            },
            {
                name: "MongoDB",
                percentLevel: 20,
                type: "Back-End"
            },
            {
                name: "Lógica de Programação",
                percentLevel: 80,
                type: "Back-End"
            },
            {
                name: "Orientação à Objetos",
                percentLevel: 60,
                type: "Back-End"
            },
        ],
        frontEnd: [
            {
                name: "Javascript",
                percentLevel: 55,
                type: "Front-End",
            },
            {
                name: "Typescript",
                percentLevel: 35,
                type: "Front-End",
            },
            {
                name: "React JS",
                percentLevel: 70,
                type: "Front-End"
            },
            {
                name: "Redux",
                percentLevel: 55,
                type: "Front-End"
            },
            {
                name: "Next JS",
                percentLevel: 70,
                type: "Front-End"
            },
            {
                name: "Vue JS",
                percentLevel: 20,
                type: "Front-End"
            },
            {
                name: "CSS3",
                percentLevel: 85,
                type: "Front-End",
            },
            {
                name: "SASS",
                percentLevel: 50,
                type: "Front-End",
            },
            {
                name: "HTML5",
                percentLevel: 100,
                type: "Front-End",
            },
            {
                name: "jQuery",
                percentLevel: 50,
                type: "Front-End",
            },
            {
                name: "Bootstrap",
                percentLevel: 82,
                type: "Front-End",
            }
        ],
        webServices: [
            {
            name: "APIs REST/RESTful",
            percentLevel: 58,
                type: "Web Services"
            },
        ],
    }

    const [skills] = useState(initialState);

    const renderSkills = (skills) => {
        return <ProgressBar skills={skills} tooltipPlace="right" />
    }

    return (
        <Fragment>
            <Head content={ 'Habilidades' } classes={'mt-2'} />
            <div className="row">
                {
                    Object.entries(skills).map((value, index) => (
                        <div key={index} className="col-12 mb-4">
                            <section className={value[0]}>
                                <Card content={ renderSkills(value[1]) } />
                            </section>
                        </div>
                    ))
                }
            </div>
        </Fragment>
    )
}

export default Skills;