import React from 'react';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light d-none d-md-flex pb-0">
                <div className="container d-block">
                    <div className="row mb-3">
                        <div className="col-12 col-md-3 col-lg-2 mt-2 text-center">
                            <h1 className="navbar-brand text-white"><Link className="text-white" to="/">wCastro</Link></h1>
                        </div>
                        <div className="col-md-9 col-lg-6 col-xl-5 col-xxl-4 mt-2 d-none d-md-block">
                            <div className="d-flex align-content-center flex-wrap justify-content-end justify-content-lg-center justify-content-xxl-start h-100 ms-3">
                                <Link className="nav-link align-self-center" to="/">HOME</Link>
                                <Link className="nav-link" to="/sobre">SOBRE</Link>
                                <Link className="nav-link" to="/curriculo">CURRÍCULO</Link>
                                <Link className="nav-link" to="/contato">CONTATO</Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-5 col-xxl-6 d-none d-lg-flex justify-content-end">
                            {/* <a href="tel:5676-4038" className="navbox text-white text-decoration-none d-none d-xxl-block ms-1 me-2">
                            <i className="fa fa-phone me-2"></i>
                            <span>(11) 5676-4038</span>
                        </a> */}
                            <a href="tel:97748-7190" className="navbox text-white text-decoration-none d-none d-xl-block ms-1 me-2">
                                <i className="fa fa-mobile-alt me-2"></i>
                                <span>(11) 97748-7190</span>
                            </a>
                            <a href="mailto:contato@wilsoncastro.dev" className="navbox text-white text-decoration-none ms-1 me-2">
                                <i className="fa fa-envelope me-2"></i>
                                <span>contato@wilsoncastro.dev</span>
                            </a>
                            <a href="https://www.linkedin.com/in/w-castro/" className="navbox text-white text-decoration-none d-none d-xxl-block ms-1 me-2">
                                <i className="fab fa-linkedin me-2"></i>
                                <span>Linkedin</span>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className="navbar navbar-expand-lg navbar-mobile d-flex d-md-none pb-0">
                <div className="container-fluid g-0">
                    <div className="row g-0">
                        <div className="col-12 text-center">
                            <h1 className="navbar-brand text-white"><Link className="text-white" to="/">wCastro</Link></h1>
                        </div>
                        <div className="col-12">
                            <div className="navbar-menu-mobile d-flex justify-content-center">
                                <NavLink activeClassName='active' exact={true} className="nav-link" to="/">HOME</NavLink>
                                <NavLink activeClassName='active' className="nav-link" to="/sobre">SOBRE</NavLink>
                                <NavLink activeClassName='active' className="nav-link" to="/curriculo">CURRÍCULO</NavLink>
                                <NavLink activeClassName='active' className="nav-link" to="/contato">CONTATO</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;