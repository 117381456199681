export const policiesWhatsappData = (
    <div className="p-2 p-md-0">
        <p>
            Eu, Wilson Castro da Paixão, me comprometo em garantir que sua privacidade seja protegida. 
            Por este motivo, criei esta Política de Privacidade para que você tenha conhecimento sobre 
            a forma de como é usada e protegida qualquer informação coletada através do meu Assistente 
            Virtual do Whatsapp.
        </p>
        <h5>Sobre a coleta de dados</h5>
        <p>
            Ao usar o meu Assistente Virtual do Whatsapp, será pedido que você forneça algumas informações 
            de identificação pessoal e sobre a vaga oferecida. As informações de identificação pessoal 
            que serão coletadas são o nome, o e-mail e a empresa em que você trabalha. Em relação as informações 
            sobre a vaga oferecida podem incluir, mas não se limitam ao nome da empresa que está oferecendo 
            a vaga, cargo, descrição da vaga, regime de trabalho e outras informações da vaga.
        </p>
        <h5>Sobre o uso e o acesso aos dados coletados</h5>
        <p>
            As informações coletadas serão utilizadas exclusivamente para que eu possa me comunicar com você 
            e me candidatar a vaga oferecida. Somente eu terei acesso as informações e nenhum dado 
            pessoal será divulgado publicamente. Me comprometo em manter o seus dados em segurança 
            e não irei compartilhar com terceiros, com exceção às plataformas descritas nesta política de privacidade.
        </p>
        <h5>Sobre o armazenamento dos dados coletados</h5>
        <p>
            Os dados coletados são enviados para uma planilha da plataforma Google Sheets e armazenados 
            dentro do Google Drive para manter os seus dados seguros.
        </p>
        <h5>Sobre as mudanças na Política de Privacidade</h5>
        <p>
            Esta Política de Privacidade pode passar por atualizações a qualquer momento. É interessante 
            que você sempre visite esta Política de Privacidade para se manter informado sobre as alterações. 
            Ao aceitar a Politica de Privacidade no meu Assistente Virtual do Whatsapp, indica que você 
            concorda com esta Política de Privacidade. Caso não concorde com esta política, 
            por favor não utilizar o Assistente Virtual.
        </p>
        <h5>Dúvidas</h5>
        <p>
            Se você tiver alguma dúvida sobre esta Política de Privacidade, envie um e-mail para contato@wilsoncastro.dev.
        </p>
    </div>
)