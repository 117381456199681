import React, { Fragment } from 'react';
import { Steps } from 'rsuite';
import { Progress } from 'rsuite';
import { Icon } from 'rsuite';

const ProgressBarSteps = ({ id, name, percentLevel }) => {
    const { Line } = Progress;

    let skill = {
        level: {
            basic: percentLevel < 32,
            intermediary: percentLevel >= 32 && percentLevel < 66,
            advanced: percentLevel >= 66 && percentLevel < 90,
            expert: percentLevel >= 100,
        },
        color: {
            basicColor: percentLevel >= 0,
            intermediaryColor: percentLevel >= 32,
            advancedColor: percentLevel >= 66,
            expertColor: percentLevel >= 100
        }
    }

    return ( 
        <Fragment>
            <div className="clearfix">
                <h6 className="float-start">{name}</h6>
            </div>
            <div className="rs-progress-wrapper">
                <Steps>
                    <div className="rs-progress-level">
                        <Steps.Item className={(skill.color.basicColor ? "rs-circle-success " : "") + (skill.level.basic ? "rs-progress-basic" : "")} icon={<Icon icon="circle" />} />
                    </div>
                    <div className="rs-progress-level">
                        <Steps.Item className={(skill.color.intermediaryColor ? "rs-circle-success " : "") + (skill.level.intermediary ? "rs-progress-intermediary" : "")}  icon={<Icon icon="circle" />} />
                    </div>
                    <div className="rs-progress-level">
                        <Steps.Item className={(skill.color.advancedColor ? "rs-circle-success " : "") + (skill.level.advanced ? "rs-progress-advanced" : "")}  icon={<Icon icon="circle" />} />
                    </div>
                    <div className="rs-progress-level">
                        <Steps.Item className={(skill.color.expertColor ? "rs-circle-success " : "") + (skill.level.expert ? "rs-progress-expert" : "")}  icon={<Icon icon="circle" />} />
                    </div>
                </Steps>
                <Line className="rs-progress-step" percent={percentLevel} status='active' strokeColor={'#21bf48'} strokeWidth={6} showInfo={false} />
            </div>
        </Fragment>
    )
}

export default ProgressBarSteps;