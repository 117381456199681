import React, {Fragment, useState, useContext} from 'react';
import Card from '../cards/Card';
import QRcodeWhatsapp from '../../assets/images/qr-codes/qr-code-whatsapp.svg';
import Context from '../../contexts/Context';

const PopoverWhatsapp = () => {
    const initialState = {
		show: false
	}

	const [popoverWhatsapp, setpopoverWhatsapp] = useState(initialState);
    const { showPopoverWhatsapp } = useContext(Context);

	const renderPopoverWhatsapp = () => {
		return (
			<Fragment>
                <div className="text-end close">
                     <span onClick={() => { setpopoverWhatsapp({ show: false })}}>{<i className="fas fa-times"></i> }</span>
                </div>
				<div className="row">
					<div className="col-12 pb-3 pb-sm-0">
                        <p className='m-0'>
                            Caso sinta a necessidade de mais informações ou para um contato mais 
                            direto comigo, sinta-se à vontade para me enviar uma mensagem pelo <strong>Whatsapp</strong>.
                        </p>
                        <div>
                            <img src={QRcodeWhatsapp} alt="QR Code Whatsapp" />
                        </div>
                        <p>
                            Para iniciar uma conversa comigo pelo <strong>Whatsapp</strong>,
                            aponte a câmera do seu celular para ler o QR Code acima,
                            ou clique no botão ao lado.
                        </p>
					</div>
				</div>
			</Fragment>
		)
	}

	return (
        <Fragment>
		{ showPopoverWhatsapp.show ? ( 
            <div className="d-none d-lg-block">
                <div className={'background-popover ' + (popoverWhatsapp.show ? '' : 'd-none')} onClick={() => { setpopoverWhatsapp({ show: false })}}>
                    <Card content={renderPopoverWhatsapp()} classes={'card-float-whatsapp fade-in ' + (popoverWhatsapp.show ? '' : 'd-none')} />
                </div>
            </div>
        ) : null}
        </Fragment>
	)
}
  
export default PopoverWhatsapp;