import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { portfolioData } from '../../data/portfolioData';
import Modal from '../../components/modals/Modal';
import Credits from '../../components/projects/Credits';
import BarCookies from '../policies/BarCookies';
import PopoverWhatsapp from '../popover/PopoverWhatsapp';
import { policiesData } from '../../data/policiesData';
import { policiesWhatsappData } from '../../data/policiesWhatsappData';
import GithubWhite from '../../assets/images/logos-svgs/github-white-logo.svg';

const Footer = () => {
    const [credits] = useState(portfolioData[0].credits)
    const [policies] = useState(policiesData);
    const [policiesWhatsapp] = useState(policiesWhatsappData);
    const [github] = useState({
        title: "GitHub",
        image: GithubWhite,
        link: "https://github.com/wilsoncastrodev" 
    });

    var openModal = window.location.hash === "#politica-privacidade";
    var openWhatsappModal = window.location.hash === "#politica-privacidade-whatsapp";

    return (
        <footer>
            <div className="container">
                <div className="row mb-3 py-1">
                    <div className="col-12 col-xl-3 text-center text-xl-left">
                        <div className="logo">
                            <Link className="text-white" to="/">wCastro</Link>
                        </div>
                    </div>
                    <div className="col-8 d-none d-xl-block">
                        <div className="d-flex align-content-center flex-wrap justify-content-end justify-content-lg-start h-100">
                            <Link className="align-self-center" to="/">HOME</Link>
                            <Link to="/sobre">SOBRE</Link>
                            <Link to="/curriculo">CURRÍCULO</Link>
                            <Link to="/contato">CONTATO</Link>
                            <span className="separator">|</span>
                            <Modal title={"Créditos"}
                                content={<Credits credits={credits} classes="p-2" title={true} />}
                                type={"link"}
                            />
                            <Modal title={"Política de Privacidade"}
                                content={policies}
                                type={"link"}
                                classes="rs-modal-md"
                                openModal={openModal}
                            />
                            <span className="d-none">
                                <Modal title={"Política de Privacidade"}
                                    content={policiesWhatsapp}
                                    type={"link"}
                                    classes="rs-modal-md"
                                    openModal={openWhatsappModal}
                                />
                            </span>       
                        </div>
                    </div>
                    <div className="col-1 d-none d-xl-block">
                        <div className="d-flex justify-content-center">
                            <a href={github.link} target="_blank" rel="noopener noreferrer">
                                <img src={github.image} alt={github.title} />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 d-block d-lg-none mb-2">
                        <div className="d-flex flex-column text-center h-100">
                            <Link className="align-self-center" to="/">HOME</Link>
                            <Link to="/sobre">SOBRE</Link>
                            <Link to="/curriculo">CURRÍCULO</Link>
                            <Link to="/contato">CONTATO</Link>
                            <Modal title={"Créditos"}
                                content={<Credits credits={credits} classes="p-2" title={true} />}
                                type={"link"}
                            />
                            <Modal title={"Política de Privacidade"}
                                content={policies}
                                type={"link"}
                                classes="rs-modal-md"
                            />
                            <a className="my-1" href={github.link} target="_blank" rel="noopener noreferrer">
                                <img src={github.image} alt={github.title} width="40"/>
                            </a>
                            <span className="mt-4">
                                <button className="btn btn-outline-light w-min-content" 
                                    onClick={() => document.querySelector('html').scrollIntoView({behavior:"smooth"}) }
                                >
                                    <i className="fas fa-angle-double-up icon-arrow-top"></i>
                                </button>
                            </span>
                            <span className="fs-md text-white mt-3">
                                SUBIR AO TOPO
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <BarCookies />

            <PopoverWhatsapp />
        </footer>
    )
}

export default Footer;