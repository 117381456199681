import React, { useState } from 'react';
/* import CardAlt from '../../../components/cards/CardAlt'; */
import CardBackground from '../../../components/cards/CardBackground';
import { Link } from 'react-router-dom';
import BgProfile from '../../../assets/images/backgrounds/bg-profile.jpg';
import IconProfile1 from '../../../assets/images/icons/aprendizagem.png';
import IconProfile2 from '../../../assets/images/icons/desenvolvedor.png';
import IconProfile3 from '../../../assets/images/icons/web_design.png';
import IconProfile4 from '../../../assets/images/icons/varios_dispositivos.png';
import IconProfile5 from '../../../assets/images/icons/internet.png';

const Profile = () => {
    const initialState = {
        name: "WILSON CASTRO DA PAIXÃO",
        profession: "Desenvolvedor Full Stack PHP Pleno",
        IconProfile1: IconProfile1,
        IconProfile2: IconProfile2,
        IconProfile3: IconProfile3,
        IconProfile4: IconProfile4,
        IconProfile5: IconProfile5
    }

    const [profile] = useState(initialState);

    const renderProfile = () => {
        return (
            <div className="card-profile-info text-center">
                <div className="mt-3">
                    <h2>{ profile.name }</h2>
                    <span className="fw-bolder-light">{ profile.profession }</span>
                </div>
                <div className="my-3">
                    <img className="icon-profile icon-profile1" src={profile.IconProfile1} alt="Ícone Perfil" />
                    <img className="icon-profile icon-profile2" src={profile.IconProfile2} alt="Ícone Perfil" />
                    <img className="icon-profile icon-profile3" src={profile.IconProfile3} alt="Ícone Perfil" />
                    <img className="icon-profile icon-profile4" src={profile.IconProfile4} alt="Ícone Perfil" />
                    <img className="icon-profile icon-profile5" src={profile.IconProfile5} alt="Ícone Perfil" />
                </div>
                <div className="btn-group-footer">
                    <div className="row">
                        <div className="col pe-1">
                            <Link className="btn btn-primary btn-md w-100" to="/curriculo">
                                <span>Ver Habilidades</span>
                            </Link>
                        </div>
                        <div className="col ps-1">
                            <Link className="btn btn-success btn-md w-100" to="/contato">
                                <span>Entrar em Contato</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <section className="profile">
            {/* <CardAlt id={'profile'} content={ renderProfile() } image={BgProfile} classes={'card-alt'}/> */}
            <CardBackground id={'word-experience'} content={ renderProfile() } image={BgProfile} classes={'card-profile'}/>
        </section>
    )
}
  
export default Profile;