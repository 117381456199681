import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({ title, tooltipPlace }) => {
    return (
        <Fragment>
            <div className="r-tooltip d-none d-sm-inline">
                <button data-delay-hide="6000" data-effect={"solid"} data-place={tooltipPlace} data-multiline={true} data-tip={ title }></button>
                <ReactTooltip />
            </div>
            <div className="r-tooltip d-inline d-sm-none">
                <button data-delay-hide="6000" data-effect={"solid"} data-place={'bottom'} data-multiline={true} data-tip={ title }></button>
                <ReactTooltip />
            </div>
        </Fragment>
    )
}

export default Tooltip;