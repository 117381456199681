import React, { useState } from 'react';
import CardImage from '../../../components/cards/CardImage';
import Head from '../../../components/heads/Head';

const Interest = () => {
    const initialState = [
        {
            title: "Desenvolvimento Web",
            background: "bg-interest-one",
        },
        {
            title: "Aplicações Web",
            background: "bg-interest-three",
        },
        {
            title: "Programação",
            background: "bg-interest-two",
        },
        {
            title: "Estudar e Aprender",
            background: "bg-interest-four",
        },
    ];

    const [interests] = useState(initialState);

    return (
        <section className="interests">
            <div className="row">
                <Head content={ 'Interesses' } classes={'mt-2'} />
                {
                    interests.map((value, key) => (
                        <div key={key} className="col-12 col-sm-6 col-lg-3 mb-4">
                            <CardImage badge={true} content={value.title} classes={'bg card-interest ' + value.background} />
                        </div>
                    ))
                }
            </div>
        </section>
    )
}
  
export default Interest;