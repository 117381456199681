import React, { Fragment, useState } from 'react';
import CardBackground from '../../../components/cards/CardBackground';
import Head from '../../../components/heads/Head';
import BgProfile from '../../../assets/images/backgrounds/bg-work-experience.jpg';
import { Timeline } from 'rsuite';
import Badge from '../../../components/badges/Badge';

const WorkExperience = () => {
    const initialState = [
        {
            job: "Desenvolvedor Full Stack Junior",
            company: "Studio Visual",
            dateIn: "Maio 2019",
            dateOut: "Outubro 2020",
            description: "Atuei na área de sustentação de aplicações Web em PHP "
                       + "utilizando o framework Laravel e o CMS Wordpress, realizando "
                       + "correções de bugs e falhas, construindo e mantendo APIs "
                       + "RESTful que integram outros sistemas e desenvolvendo novas "
                       + "features e melhorias dentro das aplicações para atender "
                       + "às necessidades dos clientes. Atuei também no desenvolvimento "
                       + "front-end de sites e portais com Wordpress.",
            stack: [
                "PHP",
                "Laravel",
                "Wordpress",
                "Mysql",
                "APIs",
                "Javascript",
                "jQuery",
                "SASS",
                "CSS3",
                "HTML5",
                "Bootstrap",
                "Linux",
                "Git",
            ]
        },
        {
            job: "Estágio em Desenvolvimento de Sistemas",
            company: "Faculdade de Tecnologia de São Caetano do Sul - Antonio Russo",
            dateIn: "Abril 2016",
            dateOut: "Maio 2017",
            description: "Estágio na área de TI. Durante o período de estágio "
                       + "desempenhei a função de programador de "
                       + "sistemas e participei do acompanhamento das "
                       + "atividades referentes ao campo de tecnologia.",
            stack: []
        },
    ];

    const [experiences] = useState(initialState);

    const renderWorkExperience = () => {
        return (
            <Fragment>
                <Head content={'Experiência'} classes="h2 mt-2" />
                <Timeline className="timeline-experience">
                    {
                        experiences.map((value, index) => (
                            <Timeline.Item key={index}>
                                <div className="rs-timeline-content">
                                    <h6>{value.job}</h6>
                                    <div className="my-1 fw-bolder-light">{value.company} / {value.dateIn} ~ {value.dateOut}</div>
                                    <p>{value.description}</p>
                                    {value.stack.length > 0 ? (
                                        <Fragment key={index}>
                                            <div className="mt-2">
                                            <small className="small-alt">Tecnologias utilizadas na empresa:</small>
                                            </div>
                                            <div className="mt-2">
                                                {
                                                    value.stack.map((technology, index) => (
                                                        <Fragment key={index}>
                                                            <Badge content={technology} classes="bg-light"/> 
                                                        </Fragment>
                                                    ))
                                                }
                                            </div>
                                        </Fragment>
                                        ) : null
                                    }
                                </div>
                            </Timeline.Item>
                        ))
                    }
                </Timeline>
            </Fragment>
        )
    }

    return (
        <section className="word-experience">
            <CardBackground id={'word-experience'} content={ renderWorkExperience() } image={BgProfile} classes={'card-timeline'}/>
        </section>
    )
}

export default WorkExperience;