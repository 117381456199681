import React, { Fragment } from 'react';
import Head from '../../components/heads/Head';
import Card from '../../components/cards/Card';

const Credits = ({ credits, classes, title }) => {
    const renderCredits = (credits) => {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        {credits.description}
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            { credits.images.length > 0 ?  
                            <div className="col-12">
                                <Head content={ 'Imagens' } classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                <ul className="list-unstyled">
                                    {
                                        credits.images.map((credit, index) => (
                                            <li key={index} className="mb-3">
                                                <div className="fw-bolder-light">
                                                    {credit.name}
                                                </div>
                                                <div className="text-start">
                                                    {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                        <i className="fas fa-link pe-1"></i>
                                                        Acessar a imagem no {credit.platform}
                                                    </a>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div> : null
                            }
                            { credits.icons.length > 0 ?  
                            <div className="col-12">
                                <Head content={ 'Ícones' } classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                <ul className="list-unstyled">
                                    {
                                        credits.icons.map((credit, index) => (
                                            <li key={index} className="mb-3">
                                                <div className="fw-bolder-light">
                                                    {credit.name}
                                                </div>
                                                <div className="text-start">
                                                    {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                        <i className="fas fa-link pe-1"></i>
                                                        Acessar os ícones no {credit.platform}
                                                    </a>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div> : null
                            }
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            { credits.packages.length > 0 ?  
                            <div className="col-12">
                                <Head content={ 'Pacotes do Node' } classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                <ul className="list-unstyled">
                                    {
                                        credits.packages.map((credit, index) => (
                                            <li key={index} className="mb-3">
                                                <div className="fw-bolder-light">
                                                    {credit.name}
                                                </div>
                                                <div className="text-start">
                                                    {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                        <i className="fas fa-link pe-1"></i>
                                                        Acessar o pacote no {credit.platform}
                                                    </a>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div> : null
                            }
                            { credits.apis.length > 0 ?  
                            <div className="col-12">
                                <Head content={ 'APIs' } classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                <ul className="list-unstyled">
                                    {
                                        credits.apis.map((credit, index) => (
                                            credit.name ?
                                            <li key={index} className="mb-3">
                                                <div className="fw-bolder-light">
                                                    {credit.name}
                                                </div>
                                                <div className="text-start">
                                                    {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                        <i className="fas fa-link pe-1"></i>
                                                        Acessar a documentação da API no {credit.platform}
                                                    </a>
                                                </div>
                                            </li> : null
                                        ))
                                    }
                                </ul>
                            </div> : null
                            }
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            { credits.logos.length > 0 ?  
                            <div className="col-12">
                                <Head content={ 'Logos' } classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                <ul className="list-unstyled">
                                    {
                                        credits.logos.map((credit, index) => (
                                            <li key={index} className="mb-3">
                                                <div className="fw-bolder-light">
                                                    {credit.name}
                                                </div>
                                                <div className="text-start">
                                                    {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                        <i className="fas fa-link pe-1"></i>
                                                        Acessar o logo no {credit.platform}
                                                    </a>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div> : null
                            } 
                            { credits.references.length > 0 ?  
                            <div className="col-12">
                                <Head content={ 'Referências de Desenvolvimento' } classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                <ul className="list-unstyled">
                                    {
                                        credits.references.map((credit, index) => (
                                            <li key={index} className="mb-3">
                                                <div className="fw-bolder-light">
                                                    {credit.name}
                                                </div>
                                                <div className="text-start">
                                                    {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                        <i className="fas fa-link pe-1"></i>
                                                        Acessar a documentação do {credit.platform}
                                                    </a>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div> : null
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className={classes}>
                {
                    !title ? (
                        <Head content={ 'Créditos' } classes={'mt-2 d-inline-block ' + classes.textColor} />
                    ) : null
                }
                <Card content={ renderCredits(credits, classes) } />
            </div>
        </Fragment>
    )
}
  
export default Credits;