import React, { Fragment } from 'react';
import Waves from '../waves/Waves';
import Particles from '../particles/Particles';

const Card = ( {id, content, waves, particles, classes} ) => {
  return (
    <Fragment>
        <div key={id} className={'card card-simple p-0 ' + classes}>
            { particles ? <Particles /> : null }
            <div className='card-body'>
                { content }
            </div>
            { waves ? <Waves /> : null }
        </div>
    </Fragment>
  )
}
  
export default Card;