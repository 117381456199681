import React, { Fragment } from 'react';

const CardImageFull = ({ id, image, alt }) => {
	return (
		<Fragment key={id}>
			<div className="card-bg-full">
				<img src={image} alt={alt} />
			</div>
		</Fragment>
	)
}
	
export default CardImageFull;