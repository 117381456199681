import React, { Fragment, useState } from 'react';
import Card from '../../../components/cards/Card';
import Head from '../../../components/heads/Head';
import ProgressBar from '../../../components/progress/ProgressBar';

const SkillsComplementary = () => {
    const initialState = {
        cms: [
            {
                name: "Wordpress",
                percentLevel: 40,
                type: "CMS",
            }
        ],
        developmentEnvironment: [
            {
                name: "Docker/Docker Compose",
                percentLevel: 50,
                type: "Ambiente de Desenvolvimento"
            },
        ],
        versionControl: [
            {
                name: "Git",
                percentLevel: 50,
                type: "Controle de Versão"
            },
        ],
        servers: [
            {
                name: "Apache2",
                percentLevel: 35,
                type: "Servidores"
            },
            {
                name: "Nginx",
                percentLevel: 30,
                type: "Servidores"
            }
        ],
        so: [
            {
                name: "Linux",
                percentLevel: 45,
                type: "Sistemas Operacionais"
            },
            {
                name: "Windows",
                percentLevel: 70,
                type: "Sistemas Operacionais"
            }
        ],
    }

    const [skills] = useState(initialState);

    const renderSkills = (skills) => {
        return <ProgressBar skills={skills} tooltipPlace="left" />
    }

    return (
        <Fragment>
            <Head content={ 'Outras Habilidades' } classes={'mt-2'} />
            <div className="row">
                {
                    Object.entries(skills).map((value, index) => (
                        <div key={index} className="col-12 mb-4">
                            <section className={value[0]}>
                                <Card content={ renderSkills(value[1]) }/>
                            </section>
                        </div>
                    ))
                }
            </div>
        </Fragment>
    )
}

export default SkillsComplementary;