import React, { Fragment, useState } from 'react';
import ProgressBarSteps from './ProgressBarSteps';
import Head from '../heads/Head';
import Tooltip from '../tooltips/Tooltip';

const ProgressBar = ({ skills, tooltipPlace }) => {
    const initialState = {
        title: "Esta autoavaliação de níveis de <br />habilidades é feita com base nos meus estudos,<br />"
             + "experiências em desenvolvimento de<br /> projetos pessoais e profissionais."
    }

    const [tooltip] = useState(initialState);
    const [showTooltip] = useState(false);

    return (
        <Fragment>
            <Head content={skills[0].type} classes="h2 mt-2 d-inline-block" />
            {
                showTooltip ? <Tooltip title={tooltip.title} tooltipPlace={ tooltipPlace }/> : ""
            }
            {
                skills.map((value, index) => (
                    <Fragment key={index}>
                        <ProgressBarSteps id={index} name={value.name} percentLevel={value.percentLevel} />
                    </Fragment>
                ))
            }
        </Fragment>
    )
}

export default ProgressBar;