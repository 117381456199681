import React, { Fragment, useState } from 'react';
import Head from '../../../components/heads/Head';
import Project from '../../../components/projects/Project';
import { portfolioData } from '../../../data/portfolioData';

const Portfolio = () => {
    const [projects] = useState(portfolioData)

    return (
        <Fragment>
            <Head content={'Projetos Pessoais'} classes={'mt-2'} />
            <section className="porfolio">
                <div className="row">
                {
                    projects.map((project, index) => (
                        <div key={index} className="col-12 mb-4">
                            <Project project={project} id={index}/>
                        </div>
                    ))
                }
                </div>
            </section>
        </Fragment>
    )
}

export default Portfolio
