import React, { Fragment } from 'react';

const CardBackground = ({ id, content, classes, image, alt }) => {
	return (
		<Fragment key={id}>
			<div className="card-bg">
				<img src={image} alt={alt} />
			</div>
			<div className={'card card-simple p-0 ' + classes}>
				<div className='card-body'>
					{ content }
				</div>
			</div>
		</Fragment>
	)
}
	
export default CardBackground;