import React, { Fragment } from 'react';
import Screenshots from '../projects/Screenshots';
import Overview from '../projects/Overview';
import Credits from './Credits';
import Head from '../../components/heads/Head';

const ProjectDetails = ({ project, id }) => {
    let classColor = {
        bgColor: id % 2 !== 0 ? 'bg-green' : '',
        textColor: id % 2 !== 0 ? 'text-success' : '',
        btnColor: id % 2 !== 0 ? 'btn-success' : '',
        badgeColor: id % 2 !== 0 ? 'bg-success mb-2 me-2' : 'bg-primary mb-2 me-2',
        linkColor: id % 2 !== 0 ? "link-hover-success" : 'link-hover-primary'
    };

    const renderProjectDetails = (project, id, classColor) => {
        return (
            <Fragment>
                <div style={{ paddingRight: "2px", paddingLeft: "6px" }}>
                    <div className="row pe-1">
                        <div className="col-12 mb-4">
                            <Overview project={project} id={id} classes={classColor} />
                        </div>
                        {
                            project.tasks ? (
                            <div className="col-lg-4">
                                <div className="row">
                                    <div className="col-12 mb-4">
                                        {project.tasks}
                                    </div>
                                    <div className="col-12 mb-4">
                                        {project.tasksCompleted}
                                    </div>
                                </div>
                            </div>
                            ) : null
                        }
                        {
                            project.commits ? (
                                <div className="col-lg-8 mb-4">
                                    {project.commits}
                                </div>
                            ) : null
                        }
                        {
                            Object.keys(project.credits).length > 0 ? (
                                <div className="col-12 mb-4">
                                    <Credits credits={project.credits} classes={classColor} />
                                </div>
                            ) : null
                        }
                        <Head content={ 'Imagens do Projeto' } classes={'mt-2 d-inline-block ' + classColor.textColor} />
                        {
                            project.screenMobile.length > 0 ? (
                                <div className="col-12">
                                    <Screenshots screenMobile={project.screenMobile} />
                                </div>
                            ) : null
                        }
                        {
                            project.screenDesktop.length > 0 ? (
                                <div className="col-12">
                                    <Screenshots screenDesktop={project.screenDesktop} />
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {renderProjectDetails(project, id, classColor)}
        </Fragment>
    )
}

export default ProjectDetails;