import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ScrollToTop from '../components/scrolls/ScrollToTop';

import Home from '../pages/home/Home'
import About from '../pages/about/About'
import CV from '../pages/cv/CV'
import Contact from '../pages/contact/Contact'

const Routes = () => {
    return (
        <Suspense fallback={'Carregando...'}>
            <ScrollToTop />
            <Switch>
                <Route exact path="/" component={ Home } />
                <Route path="/sobre" component={ About } />
                <Route path="/curriculo" component={ CV } />
                <Route path="/contato" component={ Contact } />
                <Redirect to="/" />
            </Switch>
        </Suspense>
    )
}

export default Routes;