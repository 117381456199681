import React, { Fragment, useContext } from 'react';
import Context from '../../contexts/Context';
import Card from '../cards/Card';
import Table from '../tables/Table';
import Head from '../heads/Head';
import BadgeOutline from '../badges/BadgeOutline';
import { SiAsana } from "react-icons/si";

const TasksCompletedWC = () => {
    const { tasksCompletedWC } = useContext(Context);

    const renderTasks = (tasks) => {
        const columns = [
            {
                Header: 'Tarefas',
                accessor: 'col1',
            },
        ];

        return (
            <Fragment>
                <Table data={tasks} columns={columns} />
                <div className="text-center text-md-end mt-3 mt-md-auto ms-md-2">
                    <BadgeOutline content={"Integração com API do Asana"} icon={<SiAsana size={22} />} />
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Head content={'Tarefas Concluídas'} classes={'mt-2'} />
            <Card id={'tasks'} content={renderTasks(tasksCompletedWC)} />
        </Fragment>
    );
}

export default TasksCompletedWC;