export const policiesData = (
    <div className="p-2 p-md-0">
        <p>
            Todos os dados coletados no site wilsoncastro.dev serão usados somente para responder
            aos questionamentos e esclarecer às dúvidas do usuário.
        </p>
        <p>
            Caso o usuário não concorde com esta Política de Privacidade, deverá deixar de
            acessar o site imediatamente.
        </p>
        <p>
            Este site não faz uso de cookies, apenas utiliza os dados enviados através do
            formulário da página de contato para responder às perguntas ou às dúvidas do usuário.
        </p>
        <p>
            Os outros projetos pessoais relacionados a este site são apenas demonstrativos, com intuito
            por parte do proprietário do site a aprendizagem e a divulgação de seus trabalhos. Por tanto,
            o usuário não deverá fornecer nenhuma informação pessoal sensível ou informação original
            nesses projetos, com exceção do formulário de contato deste site.
        </p>
        <p>
            Conforme mencionado anteriormente, os projetos pessoais visam apenas a aprendizagem e a
            divulgação dos mesmos, não havendo a necessidade do usuário informar quaisquer dados originais
            para a sua utilização. Nesse sentido, o proprietário não pode se responsabilizar por quaisquer
            informações originais fornecidas pelo usuário em seus projetos pessoais, sendo inteira
            responsabilidade do usuário a confidencialidade dos seus próprios dados.
        </p>
        <p>
            O uso continuado deste site será considerado como aceitação desta Política de Privacidade.
        </p>
        <p>
            Quaisquer dúvidas sobre esta Política de Privacidade, você pode entrar em contato com o proprietário do site.
        </p>
        <p>
            Proprietário: Wilson Castro da Paixão
        </p>
        <p>
            E-mail: contato@wilsoncastro.dev
        </p>
    </div>
)