import React, { Fragment, useContext } from 'react';
import Context from '../../contexts/Context';
import Card from '../cards/Card';
import Table from '../tables/Table';
import Head from '../heads/Head';
import BadgeOutline from '../badges/BadgeOutline';
import { SiBitbucket } from "react-icons/si";

const CommitsWC = () => {
    const { commitsWC } = useContext(Context);

    const renderCommits = (commits) => {
        const columns = [
            {
                Header: 'Commits',
                accessor: 'col1',
            },
            {
                Header: 'Tecnologias Aplicadas',
                accessor: 'col2',
            },
        ];

        return (
            <Fragment>
                <Table data={commits} columns={columns} />
                <div className="text-center text-md-end mt-3 mt-md-auto ms-md-2">
                    <BadgeOutline content={"Integração com API do Bitbucket"} icon={<SiBitbucket size={22}/>}/> 
                </div>
            </Fragment>
        )
    }
    
    return (
        <Fragment>
            <Head content={ 'Últimos Commits' } classes={'mt-2'}/>
            <Card id={'commit'} content={ renderCommits(commitsWC) } />
        </Fragment>
    );
}

export default CommitsWC;