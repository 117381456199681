import React, { Fragment } from 'react';
import Technology from './Technology';

const Technologies = ({technologies, classes}) => {
    const renderTechnologies = () => {
        if (technologies.length > 0) {
            return technologies.sort().map((technology, index) => {
                return (<Technology key={index} technology={technology} classes={classes} />)
            });
        }
        return null;
    }

    return (
        <Fragment>
            { renderTechnologies() }
        </Fragment>
    )
}
  
export default Technologies;