import { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    let html = document.querySelector('html');
    html.scrollIntoView({ block: 'start',  behavior: 'auto' });
  }, [pathname]);

  return (null);
}

export default withRouter(ScrollToTop);