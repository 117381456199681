import React, { Fragment } from 'react';
import { SiAsana } from "react-icons/si";
import { SiBitbucket } from "react-icons/si";

const SummaryProject = ({ content, icon, classes }) => {
    return (
        <Fragment>
            <div className="d-flex">
                <div className={"pe-3 " + (classes || 'text-base')}>
                    {icon === "Asana" ? <SiAsana size={24} /> : null}
                    {icon === "Bitbucket" ? <SiBitbucket size={24} /> : null}
                </div>
                <div>{content}</div>
            </div>
        </Fragment>
    )
}
  
export default SummaryProject;